import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import logo from '../img/Logo-green-aPureBase-geen.png';
import InternetExplorerAlert from '../util/InternetExplorerAlert';
import { authenticate } from '../apollo/authentication';


const LoginPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleSubmit = (values) => {
    setIsLoggingIn(true);
    const stopLoading = () => setIsLoggingIn(false);
    return authenticate({
      username: get(values, 'username', ''),
      password: get(values, 'password', ''),
    }).then(stopLoading)
      .then(() => history.replace('/'))
      .catch(errorMessage => message.error(`${errorMessage}`))
      .finally(() => stopLoading());
  };


  return (
    <div className="login-container">
      <div className="login-page">
        <div className="login-header">
          <img src={logo} alt="aPureBase DCR" />
          <p className="login-subtitle">
            <FormattedMessage id="dcr_slogan" defaultMessage="Data Change Request Application" />
          </p>
        </div>
        <Form form={form} onFinish={handleSubmit} className="login-form">
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="please_input_your_email" defaultMessage="Please input your email!" />,
              },
            ]}
          >
            <Input
              prefix={<MailOutlined style={{ fontSize: 13 }} />}
              placeholder={formatMessage(defineMessage({
                id: 'email',
                defaultMessage: 'Email',
              }))}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="please_input_your_password" defaultMessage="Please input your Password!" />,
              },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ fontSize: 13 }} />}
              type="password"
              placeholder={formatMessage(defineMessage({
                id: 'password',
                defaultMessage: 'Password',
              }))}
            />
          </Form.Item>
          <div className="actions">
            <Form.Item name="remember" valuePropName="checked" initialValue>
              <Checkbox>
                <FormattedMessage id="remember_me" defaultMessage="Remember me" />
              </Checkbox>
            </Form.Item>

            <NavLink className="login-form-forgot" to="/login/help">
              <FormattedMessage id="forgot_password" defaultMessage="Forgot password" />
            </NavLink>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={isLoggingIn}
          >
            <FormattedMessage id="log_in" defaultMessage="Log in" />
          </Button>
          <InternetExplorerAlert />
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
