import React from 'react';
import { Alert } from 'antd';

const isInternetExplorer = () => !!document.documentMode;

const InternetExplorerAlert = () => (isInternetExplorer()
  ? (
    <Alert
      type="info"
      showIcon
      message="We noticed you are using Internet Explorer"
      description="We recommend to use Firefox, Edge, or Chrome for an optimal experience."
    />
  )
  : null);

export default InternetExplorerAlert;
