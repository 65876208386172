import React, { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Skeleton } from 'antd';
import { isNil } from 'lodash';
import { generateMessageObject } from './generateMessageObject';
import { Optional } from './UtilTypes';

export type LocalizationShape = {
  intl: IntlShape;
  formatMessage: IntlShape['formatMessage'];
  formatMessageByStr: (str: string) => string;
  formatMessageByStrLoading: (str: Optional<string>) => ReactNode;
  pluralMessage: (key: { id: string }, amount: number, variables?: Record<string, ReactNode>) => ReactNode;
  pluralMessageWithLoading: (key: { id: string }, amount: number | undefined, variables?: Record<string, ReactNode>) => ReactNode;
};

export const useLocalization = (): LocalizationShape => {
  const intl = useIntl();

  return {
    intl,
    formatMessage: intl.formatMessage,
    formatMessageByStr: (str: string) => intl.formatMessage(generateMessageObject(str)),
    formatMessageByStrLoading: (str: Optional<string>) => str
      ? intl.formatMessage(generateMessageObject(str))
      : <Skeleton.Input active />,

    pluralMessageWithLoading: (
      key: { id: string },
      amount: number | undefined,
      variables: Record<string, ReactNode> = {},
    ) => {
      const resource = intl.formatMessage(key, {
        [key.id.replace(/\./g, '_')]: amount ?? -999,
        ...variables,
      }) as string;

      if (isNil(amount)) {
        return (
          <span>
            {resource.replace(' (-999)', '')}
              &nbsp;(<Skeleton.Avatar active size="small" shape="circle" />)
          </span>
        );
      }
      return resource;
    },

    pluralMessage: (
      key: { id: string },
      amount: number | ReactNode,
      variables: Record<string, ReactNode> = {},
    ) => intl.formatMessage(key, {
      [key.id.replace(/\./g, '_')]: amount,
      ...variables,
    }),
  };
};
