import React from 'react';
import { useRouteMatch } from 'react-router';

const DcrAccountDetail = () => {
  const match = useRouteMatch();
  const accountId = match.params.id;

  return (
    <span>Hello {accountId}</span>
  );
};

DcrAccountDetail.propTypes = {};

export default DcrAccountDetail;
