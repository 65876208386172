import React from 'react';
import gql from 'graphql-tag';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import { NoteTemplateQuery } from './__generated__/NoteTemplateQuery';


const NoteTemplateMacro: React.FC<{
  setMacro: (value: string) => void;
  readOnly : boolean;
  selectedMacro : string | null | undefined;
}> = ({ setMacro, readOnly, selectedMacro }) => {

  const localization = useLocalization();
  const { data } = useQuery<NoteTemplateQuery>(NOTE_TEMPLATE_QUERY);
  const { Option } = Select;
  const list = data?.dcrNoteTemplates.nodes ?? [];

  const onChange = (newSelected: string) => {
    setMacro(newSelected);
  };

  return (
    <div>
      <Select placeholder="Select Macro" disabled={readOnly} defaultValue={selectedMacro || undefined} onChange={onChange} style={{ width: 300 }}>
        {list.map(it => (
          <Option key={it.id} value={it.localizationKey}>
            {localization.formatMessageByStr(it.localizationKey)}
          </Option>))}
      </Select>
    </div>
  );
};


const NOTE_TEMPLATE_QUERY = gql`
    query NoteTemplateQuery {
        dcrNoteTemplates{
            hash
            totalCount
            nodes{
                id
                localizationKey
                sort
                status
            }
        }
    }
`;


export default NoteTemplateMacro;
