import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Col,
  Comment,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Result,
  Row,
  Select,
  Skeleton,
  Tooltip,
} from 'antd';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import gql from 'graphql-tag';
import { get, keys, toNumber } from 'lodash';
import { startsWith } from 'lodash/fp';
import { useMutation, useQuery } from '@apollo/client';
import { getFlag, statusIcon } from '../../../util/Util';
import { TimeAgoLabel } from '../../../components/TimeAgoLabel';
import DcrGroupedDetailTables from './DcrGroupedDetailTables';
import PostponeDcrAction from '../PostponeDcrAction';
import { DcrGroupDetailQuery, DcrGroupDetailQueryVariables } from './__generated__/DcrGroupDetailQuery';
import { SaveDcrGroupResponse } from './__generated__/SaveDcrGroupResponse';
import { DcrStatus, ResponseCodeEnum } from '../../../__generated__/globalTypes';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import NoteTemplateMacro from './NoteTemplateMacro';
import { HandleDcrAsOptOutMutation, HandleDcrAsOptOutMutationVariables } from './__generated__/HandleDcrAsOptOutMutation';

type DcrGroupDetailFormInstance = {
  note: string;
  status: ResponseCodeEnum;
};

const DcrGroupDetail = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<DcrGroupDetailFormInstance>();
  const history = useHistory();
  const match = useRouteMatch<{ groupId: string }>();
  const groupId = toNumber(match.params.groupId);

  // This is only used for passing to [DcrGroupedDetailTables] to create dynamic rules
  const [selectedResponse, setSelectedResponse] = useState<ResponseCodeEnum>();
  const [selectedMacro, setSelectedMacro] = useState<string>();
  const {
    data,
    loading,
  } = useQuery<DcrGroupDetailQuery, DcrGroupDetailQueryVariables>(DATA_QUERY, { variables: { groupId } });
  const localization = useLocalization();
  const [saveResponse, { loading: isSaving }] = useMutation<SaveDcrGroupResponse>(SAVE_RESPONSE);
  const [handleDcrAsOptOut] = useMutation<HandleDcrAsOptOutMutation, HandleDcrAsOptOutMutationVariables>(HANDLE_OPTOUT_RESPONSE);

  useEffect(() => {
    form.setFieldsValue({ note: selectedMacro ? localization.formatMessageByStr(selectedMacro) : '' });
  }, [form, selectedMacro, localization]);

  if (loading) return (
    <Skeleton active loading paragraph={{ rows: 6 }} className="dcr-group-detail-container" />
  );
  const group = data?.dcrGroup;

  if (!group) return (
    <Result
      status="404"
      title={<FormattedMessage
        id="no_dcr_found_by_id"
        defaultMessage="No DCR found by the ID: {id}"
        values={{ id: groupId }} />}
      extra={(
        <Button type="primary" onClick={() => history.push('/')}>
          <FormattedMessage id="go_back" defaultMessage="Go Back" />
        </Button>
      )}
    />
  );


  const Item = ({ label, value }: { label: React.ReactNode, value: React.ReactNode }) => (
    <Col lg={8} md={12} sm={24}>
      <b>{label}</b>: {value || <FormattedMessage id="not_applicable_short" defaultMessage="N/A" />}
    </Col>
  );

  const readOnly = group.status !== DcrStatus.Open;
  const onSend = (values: DcrGroupDetailFormInstance) => {
    // As all the input for local ids are dynamic, we need to catch all the keys and calculate from there.
    // example: `local-id-5: 'abc'` means that the local id for dcr ID '5' is 'abc'

    const localIds = keys(values)
      .filter(startsWith('local-id'))
      .map(key => {
        const groups = key.split('-');
        return {
          dcrId: groups[groups.length - 1],
          // @ts-ignore
          localId: values[key],
        };

      });
    const input = {
      groupId,
      localIds,
      responseNote: values.note,
      responseCode: values.status,
      noteTemplate: selectedMacro,
      accountId: group.account.id,
      country: group.countryCode,
    };

    const savedMsg = formatMessage(defineMessage({
      id: 'response_saved_success',
      defaultMessage: 'Response saved and scheduled to be delivered back',
    }));

    saveResponse({ variables: { input } }).then(() => message.success(savedMsg));
  };

  const onOptOut = () => {
    const input = {
      groupId,
      accountId: group.account.id,
      country: group.countryCode,
    };

    const savedMsg = formatMessage(defineMessage({
      id: 'response_saved_success',
      defaultMessage: 'Response saved and scheduled to be delivered back',
    }));

    handleDcrAsOptOut({
      refetchQueries: [DATA_QUERY],
      variables: { input },
    }).then(() => message.success(savedMsg))
      .catch(() => message.error('Failed to mark HCP as opt out'));
  };
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={onOptOut}>
        {/* {formatMessage(Locale.tooltip_response_message)} */}
        Accept as Opt Out for {group.account.name}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="dcr-group-detail-container">
      <Row gutter={20}>
        <Item
          label={<FormattedMessage id="dcr_id" defaultMessage="DCR ID" />}
          value={group.id}
        />
        <Item
          label={<FormattedMessage id="source" defaultMessage="Source" />}
          // value={<NavLink to={`/source/${group.sourceId}`}>{group.sourceId}</NavLink>}
          value={group.sourceId}
        />
        <Item
          label={<FormattedMessage id="account" defaultMessage="Account" />}
          // value={<NavLink to={`/account/${group.account.id}`}>{group.account.name}</NavLink>}
          value={group.account.name}
        />
        <Item
          label={<FormattedMessage id="external_id" defaultMessage="External ID" />}
          value={group.externalId}
        />
        <Item
          label={<FormattedMessage id="user" defaultMessage="User" />}
          value={group.externalUser}
        />
        <Item
          label={<FormattedMessage id="country" defaultMessage="Country" />}
          value={<span>{group.countryName}&nbsp;&nbsp;{getFlag(group.countryCode)}</span>}
        />
        <Item
          label={<FormattedMessage id="created" defaultMessage="Created" />}
          value={<TimeAgoLabel time={group.ct} />}
        />
        <Item
          label={<FormattedMessage id="status" defaultMessage="Status" />}
          value={(
            <span>
              {group.status}
              &nbsp;
              {statusIcon[group.status] && (
                <Tooltip title={statusIcon[group.status].tooltip}>
                  {statusIcon[group.status].icon}
                </Tooltip>
              )}
              &nbsp;
              <PostponeDcrAction visible={!readOnly} group={group} />
            </span>
          )}
        />
      </Row>
      {group.note && group.note.length > 0 && (
        <Row>
          <Col span={24}>
            <Comment
              author={<b><FormattedMessage id="notes" defaultMessage="Notes" /></b>}
              content={group.note} />
          </Col>

        </Row>
      )}
      <Divider />
      <Form form={form} layout="vertical" onFinish={onSend}>
        <Row>
          <Col md={20} lg={12}>
            <Form.Item
              name="note"
              initialValue={get(group, 'response.note')}
              label={
                <span>
                  <FormattedMessage id="tooltip.response_label" defaultMessage="Response" />&nbsp;
                  <Tooltip title={<FormattedMessage
                    id="Input.response_description"
                    defaultMessage="This note will be sent along with the response when delivered back to the client system"
                  />}>
                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.65)' }} />
                  </Tooltip>
                </span>
              }
              rules={[{ max: 2000 }]}
            >
              <Input.TextArea
                rows={4}
                readOnly={readOnly}
                disabled={readOnly}
                placeholder={formatMessage(Locale.tooltip_response_message)}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24}>
            <NoteTemplateMacro
              readOnly={readOnly}
              selectedMacro={group.response?.noteTemplate?.localizationKey}
              setMacro={setSelectedMacro} />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }} align="bottom">
          <Col>
            <Form.Item
              name="status"
              initialValue={get(group, 'response.responseCode')}
              label={<FormattedMessage id="status" defaultMessage="Status" />}
              rules={[{
                required: true,
                message: <FormattedMessage id="required.status" defaultMessage="Status is required" />,
              }]}
            >
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder={formatMessage(defineMessage({
                  id: 'select_response_code',
                  defaultMessage: 'Select a response code',
                }))}
                optionFilterProp="children"
                disabled={group.status !== DcrStatus.Open}
                onChange={(e: ResponseCodeEnum) => setSelectedResponse(e)}
              >
                <Select.Option value={ResponseCodeEnum.Accept}>
                  <FormattedMessage id="accept" defaultMessage="Accept" />
                </Select.Option>
                {/* <Select.Option value={RESPONSE_CODES.DUPLICATE}> */}
                {/*   <FormattedMessage id="duplicate" defaultMessage="Duplicate" /> */}
                {/* </Select.Option> */}
                <Select.Option value={ResponseCodeEnum.Reject}>
                  <FormattedMessage id="reject" defaultMessage="Reject" />
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ marginLeft: '20px' }}>
            <Form.Item>
              <Dropdown.Button type="primary" htmlType="submit" disabled={readOnly} overlay={menu}>
                <FormattedMessage id="button.send_response" defaultMessage="Send Response" />
              </Dropdown.Button>
              <Button type="primary" htmlType="submit" disabled={readOnly} loading={isSaving} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <DcrGroupedDetailTables
          accountId={group.account.id}
          countryCode={group.countryCode}
          selectedResponse={selectedResponse}
          groupId={groupId} />
      </Form>
    </div>
  );
};

const SAVE_RESPONSE = gql`
  mutation SaveDcrGroupResponse($input: HandleDcrInput!) {
    handleDcr(input: $input) {
      id
      status
      dcrs {
        id
        entityLocalId
      }
    }
  }
`;

const HANDLE_OPTOUT_RESPONSE = gql`
  mutation HandleDcrAsOptOutMutation($input: DcrOptOutInput!) {
    handleDcrAsOptOut(input: $input) {
      id
      status
      dcrs {
        id
        entityLocalId
      }
    }
  }
`;


const DATA_QUERY = gql`
  query DcrGroupDetailQuery($groupId: Int!) {
    dcrGroup(id: $groupId) {
      id
      externalId
      account {
        id
        name
      }
      response {
        id
        note
        responseCode
        noteTemplate{
          id
          localizationKey
          sort
          status
        }
      }
      sourceId
      countryCode
      countryName
      ct
      externalUser
      hideUntil
      status
      note
      dcrs { id }
    }
  }
`;


export default DcrGroupDetail;
