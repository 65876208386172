import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Card, Col, Comment, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getEntityIcon } from '../../../util/Util';
import { DcrEntityType } from '../../../__generated__/globalTypes';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';

const ImfPersonDescription = ({ dcr }) => {
  const localization = useLocalization();
  const [tabIndex, setTabIndex] = useState('0');

  const CardItem = ({ label, value }) => (
    <Row className="imf-row-container">
      <Col span={7} className="label"><b>{label}</b></Col>
      <Col span={17}>{value}</Col>
    </Row>
  );

  const tabs = [
    {
      key: '0',
      tab: 'Basic information',
      content: (
        <>
          <CardItem
            label={localization.formatMessage(Locale.local_id)}
            value={dcr.imfEntity.localId}
          />
          <CardItem
            label={<FormattedMessage id="apurebase_id" defaultMessage="aPureBase ID" />}
            value={dcr.imfEntity.apurebaseId}
          />
          <CardItem
            label={<FormattedMessage id="firstname" defaultMessage="First Name" />}
            value={dcr.imfEntity.firstName}
          />
          <CardItem
            label={<FormattedMessage id="lastname" defaultMessage="Last Name" />}
            value={dcr.imfEntity.lastName}
          />
          <CardItem
            label={<FormattedMessage id="status" defaultMessage="Status" />}
            value={
              dcr.imfEntity.status
                ? <FormattedMessage id="active" defaultMessage="Active" />
                : <FormattedMessage id="inactive" defaultMessage="Inactive" />
            }
          />
          <CardItem
            label="Title"
            value={dcr.imfEntity.title}
          />
        </>
      ),
    },
    {
      key: '1',
      tab: 'Description',
      content: (
        <div>
          <Typography.Paragraph>
            This is a <strong>{dcr.type}</strong> request.
          </Typography.Paragraph>
          <Typography.Paragraph>
            The data under <strong>Basic information</strong> has been loaded from the aPureBase Database to give a better view of who this is.
          </Typography.Paragraph>
        </div>
      ),
    },
  ];

  return (
    <Card
      title={<span>{getEntityIcon(DcrEntityType.Person)} {dcr.imfEntity.firstName} {dcr.imfEntity.lastName}</span>}
      tabList={tabs}
      activeTabKey={tabIndex}
      onTabChange={setTabIndex}
      extra={dcr.note && <Comment
        author={<b><FormattedMessage id="notes" defaultMessage="Notes" /></b>}
        content={dcr.note}
      />}
    >
      {tabs[tabIndex].content}
    </Card>
  );
};

ImfPersonDescription.FRAGMENT = gql`
  fragment ImfPersonDescription on Person {
    id
    apurebaseId
    localId
    status
    gender
    title
    firstName
    lastName
  }
`;

export default ImfPersonDescription;
