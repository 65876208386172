import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Radio, Card, Row, Col, Dropdown } from 'antd';
import SettingFilled from '@ant-design/icons/lib/icons/SettingFilled';
import EyeInvisibleOutlined from '@ant-design/icons/lib/icons/EyeInvisibleOutlined';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import DcrGroupTable from './DcrGroupTable';
import { useLocalStorage } from '../../util/useLocalStorage';
import { DcrStatus, HiddenStatus } from '../../__generated__/globalTypes';



const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const DcrView = () => {
  const statusState = useLocalStorage('filter_status', DcrStatus.Open);
  const hiddenStatusState = useLocalStorage('filter_hidden', HiddenStatus.FILTER_HIDDEN);
  const [gearOpen, setGearOpen] = useState(false);

  return (
    <div className="dcr-view-container">
      <Card>
        <Row>
          <Col flex="auto">
            <FormattedMessage id="dcr_status" defaultMessage="DCR Status" />:&nbsp;
            <Radio.Group value={statusState[0]} buttonStyle="solid" onChange={e => statusState[1](e.target.value)}>
              <Radio.Button value={DcrStatus.Open}><FormattedMessage id="open" defaultMessage="Open" /></Radio.Button>
              <Radio.Button value={DcrStatus.Handled}><FormattedMessage id="handled" defaultMessage="Handled" /></Radio.Button>
              <Radio.Button value={DcrStatus.Sent}><FormattedMessage id="sent" defaultMessage="Sent" /></Radio.Button>
              <Radio.Button value={DcrStatus.All}><FormattedMessage id="all" defaultMessage="All" /></Radio.Button>
            </Radio.Group>
          </Col>
          <Col flex="80px">
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              visible={gearOpen}
              onVisibleChange={setGearOpen}
              overlay={(
                <Card style={{ padding: 20 }}>
                  <Radio.Group value={hiddenStatusState[0]} onChange={e => hiddenStatusState[1](e.target.value)}>
                    <Radio value={HiddenStatus.FILTER_HIDDEN} style={radioStyle}>
                      <FormattedMessage id="filter_postponed" defaultMessage="Filter postponed" />
                    </Radio>
                    <Radio value={HiddenStatus.ONLY_HIDDEN} style={radioStyle}>
                      <FormattedMessage id="only_postponed" defaultMessage="Only postponed" />&nbsp;
                      <EyeInvisibleOutlined />
                    </Radio>
                    <Radio value={HiddenStatus.ALL} style={radioStyle}>
                      <FormattedMessage id="all" defaultMessage="All" />&nbsp;
                      <EyeOutlined />
                    </Radio>
                  </Radio.Group>
                </Card>
              )}
            >
              <SettingFilled style={{ fontSize: 18, cursor: 'pointer', float: 'right' }} />
            </Dropdown>
          </Col>
        </Row>
      </Card>
      <DcrGroupTable hiddenStatusState={hiddenStatusState} statusState={statusState} />
    </div>
  );
};


export default DcrView;
