/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DcrEntityType {
  Address = "Address",
  Link = "Link",
  Person = "Person",
  Site = "Site",
  Unknown = "Unknown",
}

export enum DcrGroupOrderField {
  ACCOUNT_NAME = "ACCOUNT_NAME",
  COUNTRY = "COUNTRY",
  CREATED = "CREATED",
  EXTERNAL_ID = "EXTERNAL_ID",
  EXTERNAL_USER = "EXTERNAL_USER",
  ID = "ID",
  NUMBER_OF_FIELDS = "NUMBER_OF_FIELDS",
  TYPE = "TYPE",
}

export enum DcrStatus {
  All = "All",
  Handled = "Handled",
  Open = "Open",
  Sent = "Sent",
}

export enum DcrType {
  Create = "Create",
  Delete = "Delete",
  Info = "Info",
  Unknown = "Unknown",
  Update = "Update",
}

export enum HiddenStatus {
  ALL = "ALL",
  FILTER_HIDDEN = "FILTER_HIDDEN",
  ONLY_HIDDEN = "ONLY_HIDDEN",
}

export enum ResponseCodeEnum {
  Accept = "Accept",
  Duplicate = "Duplicate",
  Reject = "Reject",
}

export interface DcrGroupCriteria {
  accountIds?: number[] | null;
  countryCodes?: string[] | null;
  entityTypes?: DcrEntityType[] | null;
  exactExternalId?: string | null;
  externalId?: string | null;
  externalUser?: string | null;
  hiddenStatus?: HiddenStatus | null;
  order: DcrGroupOrder;
  sourceId?: number | null;
  status?: DcrStatus | null;
  types?: DcrType[] | null;
}

export interface DcrGroupOrder {
  order: DcrGroupOrderElement[];
  page: number;
  perPage?: number | null;
}

export interface DcrGroupOrderElement {
  direction: any;
  field: DcrGroupOrderField;
}

export interface DcrOptOutInput {
  accountId: number;
  country: string;
  groupId: number;
}

export interface HandleDcrInput {
  accountId: number;
  country: string;
  groupId: number;
  localIds?: HandleDcrLocalIdInput[] | null;
  noteTemplate?: string | null;
  responseCode: string;
  responseNote?: string | null;
}

export interface HandleDcrLocalIdInput {
  dcrId: number;
  localId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
