/* eslint-disable dot-notation,no-underscore-dangle */
import React, { useEffect } from 'react';
import { find, get, isArray, join, keys, sum, uniq, values } from 'lodash';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Grid, Input, Table, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import SelectOutlined from '@ant-design/icons/lib/icons/SelectOutlined';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import Highlighter from 'react-highlight-words';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import moment, { Moment } from 'moment';
import gql from 'graphql-tag';
import { TableProps } from 'antd/lib/table/Table';
import { ColumnsType } from 'antd/lib/table/interface';
import { useLocalStorage } from '../../util/useLocalStorage';
import { getEntityIcon, getFlag, getTypeIcon, localizedType } from '../../util/Util';
import { StateArray } from '../../util/UtilTypes';
import { DcrEntityType, DcrGroupOrderField, DcrStatus, DcrType, HiddenStatus } from '../../__generated__/globalTypes';
import { DcrGroupQuery, DcrGroupQuery_dcrGroups_nodes, DcrGroupQueryVariables } from './__generated__/DcrGroupQuery';
import { DcrGroupAutoComplete } from './__generated__/DcrGroupAutoComplete';


const reactJoin = (elements: React.ReactNode[]) => (
  <>
    {elements.map((el, index) => (
      <span key={index}>{el}{index + 1 < elements.length ? ', ' : ''}</span>
    ))}
  </>
);

const sorters: { [k in DcrGroupOrderField]?: { key: number, enum: k } } = {
  ID: { key: 1, enum: DcrGroupOrderField.ID },
  EXTERNAL_ID: { key: 2, enum: DcrGroupOrderField.EXTERNAL_ID },
  ACCOUNT_NAME: { key: 3, enum: DcrGroupOrderField.ACCOUNT_NAME },
  EXTERNAL_USER: { key: 4, enum: DcrGroupOrderField.EXTERNAL_USER },
  COUNTRY: { key: 5, enum: DcrGroupOrderField.COUNTRY },
  CREATED: { key: 6, enum: DcrGroupOrderField.CREATED },
  NUMBER_OF_FIELDS: { key: 7, enum: DcrGroupOrderField.NUMBER_OF_FIELDS },
};

type DcrGroupTableProps = {
  statusState: StateArray<DcrStatus>;
  hiddenStatusState: StateArray<HiddenStatus>;
};


type DataPrepared = DcrGroupQuery_dcrGroups_nodes & {
  groupId: number;
  types: DcrType[];
  entity: string;
  noFields: number;
  children?: React.ReactNode;
};

const DcrGroupTable: React.FC<DcrGroupTableProps> = ({
  statusState: [status, setStatus],
  hiddenStatusState: [hiddenStatus, setHiddenStatus],
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { xl } = Grid.useBreakpoint();

  const [externalId, setExternalId] = useLocalStorage('group_filter_externalId', '');
  const [accountIds, setAccountIds] = useLocalStorage<number[]>('group_filter_accountId', []);
  const [externalUser, setExternalUser] = useLocalStorage('group_filter_external_user', '');
  const [countryCodes, setCountryCodes] = useLocalStorage<string[]>('group_filter_country_codes', []);
  const [types, setTypes] = useLocalStorage('group_filter_types', []);
  const [entities, setEntities] = useLocalStorage('group_filter_entities', []);
  const [page, setPage] = useLocalStorage('group_filter_page', 1);
  const [perPage, setPerPage] = useLocalStorage('group_filter_perPage', 10);
  const [order, setOrder] = useLocalStorage<{ [key in DcrGroupOrderField]?: 'ascend' | 'descend' }>('group_order', { CREATED: 'ascend' });

  const { data: autoCompleteData } = useQuery<DcrGroupAutoComplete>(AUTO_COMPLETE_DATA);
  const { data, loading, refetch } = useQuery<DcrGroupQuery, DcrGroupQueryVariables>(DATA_QUERY, {
    variables: {
      criteria: {
        externalId,
        accountIds,
        externalUser,
        countryCodes,
        types,
        entityTypes: entities,
        status,
        hiddenStatus,
        order: {
          page,
          perPage,
          order: keys(order).map(k => {
            const field = k as DcrGroupOrderField;
            const direction = order[field];
            return { field, direction };
          }),
        },
      },
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!loading) refetch();
  }, [loading, refetch]);

  const handleChange: TableProps<DataPrepared>['onChange'] = (pagination, filters, sorter) => {
    // Pagination
    setPage(pagination.current ?? 1);
    setPerPage(pagination.pageSize ?? 10);

    // Filters
    // @ts-ignore
    setAccountIds(filters['account.name']);
    // @ts-ignore
    setCountryCodes(filters.countryCode);
    // @ts-ignore
    setEntities(filters.entity);
    // @ts-ignore
    setTypes(filters.types);

    // Sorters
    const orders = (isArray(sorter) ? sorter : [sorter])
    // @ts-ignore
      .filter(s => s && s.column && s.column.sorter && s.column.sorter.multiple)
      .reduce((acc, curr) => {
        // @ts-ignore
        const test = find(values(sorters), s => s.key === curr.column.sorter.multiple);
        // @ts-ignore
        return { ...acc, [test.enum]: curr.order };
      }, {});
    // @ts-ignore
    setOrder(orders);
  };

  const clearFilters = () => {
    setStatus(DcrStatus.Open);
    setHiddenStatus(HiddenStatus.FILTER_HIDDEN);
    setExternalId('');
    setAccountIds([]);
    setExternalUser('');
    setCountryCodes([]);
    setTypes([]);
    setEntities([]);
    setPage(1);
    setPerPage(10);
    setOrder({ CREATED: 'ascend' });
  };

  const columns: ColumnsType<DataPrepared> = [
    {
      dataIndex: 'id',
      sorter: { multiple: sorters.ID?.key },
      sortOrder: order.ID,
      title: <FormattedMessage id="id" defaultMessage="ID" />,
    },
    {
      dataIndex: 'externalId',
      sorter: { multiple: sorters.EXTERNAL_ID?.key },
      sortOrder: order.EXTERNAL_ID,
      title: <FormattedMessage id="external_id" defaultMessage="External ID" />,
      filterDropdown: ({ confirm }) => (
        <Input.Search
          autoFocus
          placeholder={intl.formatMessage(defineMessage({
            id: 'placeholder.search_group_id',
            defaultMessage: 'Search by Group ID',
          }))}
          onSearch={value => {
            setExternalId(value);
            confirm();
          }}
        />
      ),
      filterIcon: () => (
        <SearchOutlined style={{ ...(externalId ? { color: '#1890ff' } : { opacity: 0.45 }) }} />
      ),
      render: (text: string, record: DataPrepared) => (
        <Tooltip
          placement="topLeft"
          title={<FormattedMessage
            id="tooltip.external_id"
            defaultMessage="The external system specific DCR ID" />}
                >
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[externalId]}
            autoEscape
            textToHighlight={record.externalId.toString()}
          />
        </Tooltip>
      ),
    },
    {
      dataIndex: ['account', 'name'],
      title: <FormattedMessage id="account" defaultMessage="Account" />,
      sorter: { multiple: sorters.ACCOUNT_NAME?.key },
      sortOrder: order.ACCOUNT_NAME,
      filters: (autoCompleteData?.accounts?.nodes ?? []).map(account => ({
        text: account.name,
        value: account.id,
      })),
      filteredValue: accountIds,
    },
    {
      dataIndex: 'externalUser',
      title: <FormattedMessage id="user" defaultMessage="User" />,
      sorter: { multiple: sorters.EXTERNAL_USER?.key },
      sortOrder: order.EXTERNAL_USER,
      filterDropdown: ({ confirm }) => (
        <Input.Search
          autoFocus
          onSearch={value => {
            setExternalUser(value);
            confirm();
          }}
        />
      ),
      filterIcon: () => (
        <SearchOutlined style={{ ...(externalUser ? { color: '#1890ff' } : { opacity: 0.45 }) }} />
      ),
      render: (text: string) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[externalUser]}
          autoEscape
          textToHighlight={(text || '').toString()}
        />
      ),
    },
    {
      dataIndex: 'countryCode',
      title: <FormattedMessage id="country" defaultMessage="Country" />,
      sorter: { multiple: sorters.COUNTRY?.key },
      sortOrder: order.COUNTRY,
      filters: get(autoCompleteData, 'countries', []).map(c => ({
        text: c.name,
        value: c.code,
      })),
      filteredValue: countryCodes,
      render: getFlag,
    },
    {
      dataIndex: 'types',
      title: <FormattedMessage id="type" defaultMessage="Type" />,
      filters: [
        { text: <FormattedMessage id="create" defaultMessage="Create" />, value: DcrType.Create },
        { text: <FormattedMessage id="update" defaultMessage="Update" />, value: DcrType.Update },
        { text: <FormattedMessage id="delete" defaultMessage="Delete" />, value: DcrType.Delete },
        { text: <FormattedMessage id="info" defaultMessage="Info" />, value: DcrType.Info },
      ].map(({ text, value }) => ({
        value,
        text: (
          <span>
            {getTypeIcon(value)}
            {' '}
            {text}
          </span>
        ),
      })),
      filteredValue: types,
      render: (typeValues: DcrType[]) => {
        const tValues = uniq(typeValues);
        if (!xl) return (
          <Tooltip title={<>{tValues.map(localizedType)}</>}>
            {tValues.map(tv => getTypeIcon(tv))}
          </Tooltip>
        );
        return (
          <span>
            {reactJoin(tValues.map(tv => (
              <span>
                {localizedType(tv)}&nbsp;
                {getTypeIcon(tv)}
              </span>
            )))}
          </span>
        );
      },
    },
    {
      dataIndex: 'entity',
      title: <FormattedMessage id="entity" defaultMessage="Entity" />,
      filters: (['Person', 'Site', 'Address', 'Link'] as DcrEntityType[]).map(e => ({
        value: e,
        text: (
          <span>
            {getEntityIcon(e)}&nbsp;{e}
          </span>
        ),
      })),
      filteredValue: entities,
      render: (value: DcrEntityType) => {
        if (value.indexOf(',') > -1) return value;
        if (xl) return (
          <span>
            {value}&nbsp;
            {getEntityIcon(value)}
          </span>
        );
        return (
          <Tooltip title={value}>
            {getEntityIcon(value)}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'ct',
      title: <FormattedMessage id="created" defaultMessage="Created" />,
      sorter: { multiple: sorters.CREATED?.key },
      sortOrder: order.CREATED,
      render: (value: Moment) => {
        const m = moment(value);
        return (
          <Tooltip title={m.format('LLLL')}>
            {m.format('LL')}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: 'noFields',
      title: <FormattedMessage id="number_of_fields" defaultMessage="Number of fields" />,
      sorter: { multiple: sorters.NUMBER_OF_FIELDS?.key },
      sortOrder: order.NUMBER_OF_FIELDS,
    },
    {
      dataIndex: 'status',
      title: 'Status',
    },
    {
      title: '',
      render: (_, record: { __typename: string; groupId: number; }) => record.__typename === 'DcrGroup' && (
      <NavLink to={`/dcr/${record.groupId}`}>
        <SelectOutlined />
      </NavLink>
      ),
    },
  ];

  const dataPrepared = ((data && data.dcrGroups.nodes) || []).map(group => {
    const obj: DataPrepared = {
      ...group,
      groupId: group.id,
      types: uniq(group.dcrs.map(dcr => dcr.type)),
      entity: join(uniq(group.dcrs.map(dcr => dcr.entityType)), ', '),
      noFields: sum(group.dcrs.map(dcr => dcr.fields.totalCount)),
    };
    if (group.dcrs.length > 1) obj.children = group.dcrs.map(dcr => ({
      ...obj,
      ...dcr,
      types: [dcr.type],
      entity: dcr.entityType,
      created: dcr.ct,
      noFields: dcr.fields.totalCount,
    }));
    return obj;
  });

  const footer = () => (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Button
        icon={<span><ReloadOutlined />&nbsp;</span>}
        disabled={loading}
        onClick={() => refetch()}
            >
        <FormattedMessage id="refresh" defaultMessage="Refresh" />
      </Button>
      <Button icon={<CloseOutlined />} onClick={clearFilters}>
        <FormattedMessage id="clear_filters" defaultMessage="Clear filters" />
      </Button>
    </div>
  );

  return (
    <div>
      <Table<DataPrepared>
        bordered
        loading={loading}
        columns={columns as ColumnsType<DataPrepared>}
        dataSource={dataPrepared}
        rowKey={dcr => dcr.id}
        footer={footer}
        onRow={record => ({ onDoubleClick: () => history.push(`/dcr/${record.groupId}`) })}
        onChange={handleChange}
        pagination={{
          defaultCurrent: page,
          total: data?.dcrGroups.totalCount ?? 0,
          pageSize: perPage,
          pageSizeOptions: ['5', '10', '25', '50'],
          showTotal: (currentTotal, range) => `${range[0]}-${range[1]} of ${get(data, 'dcrGroups.totalCount', 0)} items`,
        }}
            />
    </div>
  );
};

const AUTO_COMPLETE_DATA = gql`
    query DcrGroupAutoComplete {
        accounts {
            hash
            nodes {
                id
                name
            }
        }
        countries {
            code
            name
        }
    }
`;

const DATA_QUERY = gql`
    query DcrGroupQuery($criteria: DcrGroupCriteria) {
        dcrGroups(criteria: $criteria) {
            hash
            totalCount
            nodes {
                id
                externalId
                externalUser
                sourceId
                account { id, name }
                countryCode
                ct
                status
                dcrs {
                    id
                    externalId
                    type
                    entityType
                    ct
                    fields {
                        hash
                        totalCount
                    }
                }
            }
        }
    }
`;

export default DcrGroupTable;
