import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { get } from 'lodash';
import { decodeJWT } from './util';


function redirectToLogin() {
  localStorage.removeItem('token');
  return window.location.replace('/login');
}

export const authenticationMiddleware = new ApolloLink((operation, forward) => {
  if (!window.location.pathname.includes('login')) {
    const token = localStorage.getItem('token');
    if (!token) return redirectToLogin();
    const user = decodeJWT(token.substr(7));
    if (user.exp > (+new Date())) return redirectToLogin();
  }
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');
    if (token) {
      return ({
        headers: {
          ...headers,
          authorization: localStorage.getItem('token') || null,
        },
      });
    }


    return headers;
  });

  return forward(operation);
});

export const authenticationAfterware = onError((errorHandler) => {
  if (get(errorHandler, 'networkError.statusCode', 200) === 401) {
    localStorage.removeItem('token');
    window.location.replace('/login');
  }
});

/**
 * @return {boolean}
 */
export const isAuthenticated = () => !!localStorage.getItem('token');
export const logout = () => localStorage.removeItem('token');

/**
 *
 * @return {{
 *   countries: [string],
 *   email: string,
 *   exp: number,
 *   id: number,
 *   iss: string,
 *   sub: string,
 * }|{}}
 */
export const useAuthToken = () => {
  const token = localStorage.getItem('token');
  if (token) return decodeJWT(token);
  return {};
};

/**
 *
 * @param {object} data
 * @param {string} data.username
 * @param {string} data.password
 */
export const authenticate = (data) => fetch('/login', {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
}).then((res) => {
  if (res.status === 200) {
    return res.text().then((token) => {
      localStorage.setItem('token', `bearer ${token}`);
      return token;
    });
  }
  logout();

  if (res.headers.get('content-type').includes('application/json')) return res
    .json()
    .then(e => Promise.reject(get(e, 'errors[0].message', 'Something went wrong')));
  return res.text().then(errMsg => Promise.reject(errMsg));
});
