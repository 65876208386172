import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import ShareAltOutlined from '@ant-design/icons/lib/icons/ShareAltOutlined';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined';
import LinkOutlined from '@ant-design/icons/lib/icons/LinkOutlined';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import EyeInvisibleOutlined from '@ant-design/icons/lib/icons/EyeInvisibleOutlined';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';
import ClockCircleTwoTone from '@ant-design/icons/lib/icons/ClockCircleTwoTone';
import { DcrEntityType, DcrStatus, DcrType, HiddenStatus } from '../__generated__/globalTypes';



export const getEntityIcon = (type: DcrEntityType) => {
  let icon;
  if (type === DcrEntityType.Person) icon = <UserOutlined />;
  else if (type === DcrEntityType.Site) icon = <HomeOutlined />;
  else if (type === DcrEntityType.Address) icon = <EnvironmentOutlined />;
  else if (type === DcrEntityType.Link) icon = <ShareAltOutlined />;
  else if (type === DcrEntityType.Unknown) icon = <LinkOutlined />;
  else return type;
  return <Tooltip title={type}>{icon}</Tooltip>;
};


export const getFlag = (countryCode: string): ReactNode => <span key={countryCode} className={`flag flag-${countryCode.toLowerCase()}`} />;

export const getTypeIcon = (type: DcrType): ReactNode => {
  if (type === DcrType.Create) {
    return <PlusOutlined />;
  }
  if (type === DcrType.Update) {
    return <EditOutlined />;
  }
  if (type === DcrType.Delete) {
    return <DeleteOutlined />;
  }
  if (type === DcrType.Info) {
    return <InfoCircleOutlined />;
  }
  // else throw Error("no icon found for type " + type);

  return <></>;
};

export const localizedType = (typeValue: DcrType): ReactNode => {
  if (typeValue === DcrType.Create) return <FormattedMessage id="create" defaultMessage="Create" />;
  if (typeValue === DcrType.Update) return <FormattedMessage id="update" defaultMessage="Update" />;
  if (typeValue === DcrType.Delete) return <FormattedMessage id="delete" defaultMessage="Delete" />;
  if (typeValue === DcrType.Info) return <FormattedMessage id="info" defaultMessage="Info" />;
  return <span />;
};

export const statusIcon: Record<DcrStatus, { icon: React.ReactNode, tooltip: React.ReactNode }> = {
  All: { icon: undefined, tooltip: undefined },
  Open: {
    icon: <></>,
    tooltip: <FormattedMessage id="Tooltip.dcr_not_processed" defaultMessage="This DCR has not been handled yet" />,
  },
  Handled: {
    icon: <ClockCircleTwoTone />,
    tooltip: (
      <FormattedMessage
        id="Tooltip.dcr_waiting_to_be_sent"
        defaultMessage="This has been handled and waiting to be delivered back to the client system"
      />
    ),
  },
  Sent: {
    icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    tooltip: <FormattedMessage id="Tooltip.dcr_sent" defaultMessage="Client system has received this response" />,
  },
};

export const getStatusIcon = (status: DcrStatus): ReactNode => {
  const icon = statusIcon[status];
  if (!icon) return <></>;
  return (
    <Tooltip title={icon.tooltip}>
      {icon.icon}
    </Tooltip>
  );
};

const hiddenIcon: Record<HiddenStatus, { icon?: React.ReactNode }> = {
  [HiddenStatus.FILTER_HIDDEN]: {},
  [HiddenStatus.ONLY_HIDDEN]: { icon: <EyeInvisibleOutlined /> },
  [HiddenStatus.ALL]: { icon: <EyeOutlined /> },
};

export const getHiddenStatusIcon = (hiddenStatus: HiddenStatus) => {
  const { icon } = hiddenIcon[hiddenStatus];
  if (!icon) return <></>;
  return <span>{icon}</span>;
};


export const getStatusAttribute = (status: DcrStatus) => {
  if (status === DcrStatus.Open) return 'warning';
  if (status === DcrStatus.Handled) return 'info';
  if (status === DcrStatus.Sent) return 'success';
  return '';
};
