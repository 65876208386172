import React, { useState } from 'react';
import { Alert, Button, Col, ColProps, Comment, Form, Input, Row, Skeleton, Table } from 'antd';
import gql from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/client';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import * as JsDiff from 'diff';
import { ColumnsType } from 'antd/lib/table/interface';
import { getEntityIcon, getTypeIcon } from '../../../util/Util';
import ImfPersonDescription from './ImfPersonDescription';
import ImfSiteDescription from './ImfSiteDescription';
import ImfEntityCardModal from './ImfEntityCardModal';
import DcrFieldMapResolver from './DcrFieldMapResolvers/DcrFieldMapResolver';
import DcrFieldOldValueMapResolver from './DcrFieldMapResolvers/DcrFieldOldValueMapResolver';
import DcrFieldNewValueMapResolver from './DcrFieldMapResolvers/DcrFieldNewValueMapResolver';
import ImfEntityDescription from './ImfEntityDescription';
import ImfLinkDescription from './ImfLinkDescription';
import { DcrEntityType, DcrStatus, DcrType, ResponseCodeEnum } from '../../../__generated__/globalTypes';
import {
  DcrGroupedDetailTablesQuery,
  DcrGroupedDetailTablesQuery_dcrGroup_dcrs,
  DcrGroupedDetailTablesQuery_dcrGroup_dcrs_fields_nodes,
  DcrGroupedDetailTablesQuery_dcrGroup_dcrs_imfEntity,
  DcrGroupedDetailTablesQueryVariables,
} from './__generated__/DcrGroupedDetailTablesQuery';
import { Optional } from '../../../util/UtilTypes';
import { CheckOptOut, CheckOptOut_personOptOutStatus, CheckOptOutVariables } from './__generated__/CheckOptOut';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';


const messages = defineMessages({
  localIdPlaceholder: {
    id: 'placeholder.local_id',
    defaultMessage: 'Enter newly created local ID',
  },
});

const split = (value: Optional<string>) => {
  if (value) {
    const parts = value.split('||');
    return { label: parts[0], id: parts[1] };
  }
  return { label: '', id: '' };
};

type DcrGroupedDetailTablesProps = {
  selectedResponse: undefined | ResponseCodeEnum;
  groupId: number;
  accountId: number;
  countryCode: string;
};


type DataSourceItem = DcrGroupedDetailTablesQuery_dcrGroup_dcrs_fields_nodes;

const DcrGroupedDetailTables: React.FC<DcrGroupedDetailTablesProps> = ({
  selectedResponse,
  groupId,
  accountId,
  countryCode,
}) => {
  const { formatMessage } = useIntl();
  const client = useApolloClient();
  const localization = useLocalization();
  const {
    data,
    loading,
  } = useQuery<DcrGroupedDetailTablesQuery, DcrGroupedDetailTablesQueryVariables>(DATA_QUERY, { variables: { groupId } });
  const cardModalState = useState<DcrGroupedDetailTablesQuery_dcrGroup_dcrs_imfEntity | null>(null);
  const [test, setTest] = useState<CheckOptOut_personOptOutStatus>();

  if (loading) return (
    <Skeleton loading active paragraph={{ rows: 6 }} />
  );


  let spanSpace: ColProps = {};
  switch (data?.dcrGroup?.dcrs.length ?? 0) {
    case 1:
      spanSpace = { span: 24 };
      break;
    case 2:
      spanSpace = { md: 24, lg: 12 };
      break;
    case 3:
      spanSpace = { md: 24, lg: 8 };
      break;
    default:
      spanSpace = { md: 24 };
  }

  const readOnly = data?.dcrGroup?.status !== DcrStatus.Open;

  const submit = (e: string) => {
    // Loading the localized text before hand, as [message.success] creates the message outside this react context.
    client.query<CheckOptOut, CheckOptOutVariables>({
      query: CHECK_OPTOUT,
      variables: {
        accountId,
        country: countryCode,
        personId: e,
      },
    }).then(res => {
      if (res.data?.personOptOutStatus) setTest(res.data.personOptOutStatus);
      else setTest(undefined);
    });
  };

  const showLocalIdRow = (dcr: DcrGroupedDetailTablesQuery_dcrGroup_dcrs) => (
    (dcr.entityType === DcrEntityType.Person || dcr.entityType === DcrEntityType.Site) && dcr.type === DcrType.Create
  );

  const Column: React.FC<{ dcr: DcrGroupedDetailTablesQuery_dcrGroup_dcrs }> = ({ dcr }) => {
    if (dcr.fields.nodes.length === 0 && dcr.imfEntity) {
      return (
        <>
          <Col {...spanSpace}>
            <ImfEntityDescription dcr={dcr} />
          </Col>
        </>
      );
    }

    const columns: ColumnsType<DataSourceItem> = [
      {
        title: <FormattedMessage id="field" defaultMessage="Field" />,
        dataIndex: 'field',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (_: any, record: DataSourceItem) => <DcrFieldMapResolver value={record} />,
      },
      dcr.type === DcrType.Update && {
        title: <FormattedMessage id="old_value" defaultMessage="Old value" />,
        dataIndex: 'oldValue',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (text: any, record: DataSourceItem) => <DcrFieldOldValueMapResolver value={record} />,
      },
      {
        title: <FormattedMessage id="new_value" defaultMessage="New value" />,
        dataIndex: 'newValue',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (text: any, record: DataSourceItem) => <DcrFieldNewValueMapResolver value={record} />,
      },
      dcr.type === DcrType.Update && {
        title: <FormattedMessage id="difference" defaultMessage="Difference" />,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (_: any, record: DataSourceItem) => JsDiff
          .diffChars(
            split(record.oldValueMapped !== null ? record.oldValueMapped : record.oldValue).label,
            split(record.newValueMapped !== null ? record.newValueMapped : record.newValue).label,
          ).map((part, i) => {
            const getColor = (added: boolean | undefined, removed: boolean | undefined) => {
              if (added) return 'green';
              if (removed) return 'red';
              return 'grey';
            };
            const color = getColor(part.added, part.removed);
            return <span key={i} style={{ color }}>{part.value}</span>;
          }),
      },
    ].filter(c => c) as ColumnsType<DcrGroupedDetailTablesQuery_dcrGroup_dcrs_fields_nodes>;

    return (
      <Col {...spanSpace}>
        <Row gutter={12}>
          <Col md={24} lg={12}>
            <b><FormattedMessage id="entity" defaultMessage="Entity" /></b>:&nbsp;
            <span>{dcr.entityType}&nbsp;&nbsp;{getEntityIcon(dcr.entityType)}</span>
          </Col>
          <Col md={24} lg={12}>
            <b><FormattedMessage id="type" defaultMessage="Type" /></b>:&nbsp;
            <span>{dcr.type}&nbsp;&nbsp;{getTypeIcon(dcr.type)}</span>
          </Col>
          {dcr.type !== DcrType.Create && (
          <Col md={24} lg={12}>
            <b><FormattedMessage id="local_id" defaultMessage="Local ID" /></b>:&nbsp;
            {dcr.entityLocalId || <FormattedMessage id="not_applicable_short" defaultMessage="N/A" />}
          </Col>
          )}
          {dcr.type !== DcrType.Create && (
          <Col md={24} lg={12}>
            <b>
              <FormattedMessage id="apurebase_id" defaultMessage="aPureBase ID" />
            </b>
            {!dcr.entityApbId
              ? <FormattedMessage id="not_applicable_short" defaultMessage="N/A" />
              : <Button type="link" style={{ padding: 0 }} onClick={() => cardModalState[1](dcr.imfEntity)}>{dcr.entityApbId}</Button>}
          </Col>
          )}
          {dcr.note && (
          <Col span={24}>
            <Comment
              author={<b><FormattedMessage id="notes" defaultMessage="Notes" /></b>}
              content={dcr.note} />
          </Col>
          )}
        </Row>
        {!showLocalIdRow(dcr)
          ? (data?.dcrGroup?.dcrs?.findIndex(showLocalIdRow) ?? -1) > -1 && <Row><Form.Item /></Row>
          : (
            <Row style={{ marginTop: '20px' }}>
              <Col span={12}>
                <Form.Item
                  name={`local-id-${dcr.id}`}
                  initialValue={dcr.entityLocalId}
                  style={{ marginTop: 8 }}
                  rules={[{
                    required: selectedResponse === ResponseCodeEnum.Accept,
                    message: <FormattedMessage
                      id="required.local_id"
                      defaultMessage="Local ID is required" />,
                  }]}
                                >
                  <Input
                    disabled={readOnly}
                    onChange={(e) => submit(e.target.value)}
                    allowClear
                    placeholder={readOnly ? '' : formatMessage(messages.localIdPlaceholder)}
                    style={{ maxWidth: 620 }}
                                    />

                </Form.Item>
              </Col>
              <Col span={12}>{test && <Alert
                message={test.clientOptOut ? localization.formatMessage(Locale.dcr_opt_out_client) : localization.formatMessage(Locale.dcr_opt_out_all)}
                type="warning" />}
              </Col>
            </Row>
          )}
        <Row>
          <Col span={24}>
            <Table
              rowKey={f => f.field}
              columns={columns}
              dataSource={dcr.fields.nodes}
              pagination={false}
                        />
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <>
      <ImfEntityCardModal state={cardModalState} />
      <Row gutter={[12, 12]}>
        {(data?.dcrGroup?.dcrs ?? []).map(dcr => <Column key={dcr.id} dcr={dcr} />)}
      </Row>
      <Row style={{ height: 240 }} />
    </>
  );
};

const DATA_QUERY = gql`
    query DcrGroupedDetailTablesQuery($groupId: Int!) {
        dcrGroup(id: $groupId) {
            id
            status
            dcrs {
                id
                entityType
                type
                entityLocalId
                entityApbId
                note
                imfEntity {
                    ... on Person { ...ImfPersonDescription }
                    ... on Site { ...ImfSiteDescription }
                    ... on Affiliation { ...ImfAffiliationDescription }
                }
                fields {
                    hash
                    nodes {
                        id
                        dcrId
                        field
                        oldValue
                        newValue
                        newValueMapped
                        oldValueMapped
                        fieldApb
                    }
                }
            }
        }
    }

    ${ImfPersonDescription.FRAGMENT}
    ${ImfSiteDescription.FRAGMENT}
    ${ImfLinkDescription.FRAGMENT}
`;


const CHECK_OPTOUT = gql`
    query CheckOptOut($accountId: Int!, $country: CountryCode!, $personId: String!) {
        personOptOutStatus(accountId: $accountId, country: $country, personIdInput: $personId) {
            apbOptOut
            clientName
            clientOptOut
            ct
            ut
        }
    }
`;

export default DcrGroupedDetailTables;
