import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import moment from 'moment';

import 'antd/dist/antd.min.css';
import './css/App.css';
import './css/flags.css';
import './css/index.css';
import './main.less';

import enUS from 'antd/es/locale/en_US';
import daDK from 'antd/es/locale/da_DK';
import itIT from 'antd/es/locale/it_IT';

import { client } from './apollo/client';
import App from './App';
import 'moment/locale/da';
import 'moment/locale/it';
import 'moment/locale/fo';
import messagesDA from './localization/locale/da.json';
import messagesEN from './localization/locale/en.json';
import messagesIT from './localization/locale/it.json';
import messagesFO from './localization/locale/fo_FO.json';


const messages = {
  da: {
    moment: navigator.language,
    antd: daDK,
    messages: messagesDA,
  },
  en: {
    moment: navigator.language,
    antd: enUS,
    messages: messagesEN,
  },
  it: {
    moment: navigator.language,
    antd: itIT,
    messages: messagesIT,
  },
  fo: {
    moment: navigator.language,
    antd: enUS,
    messages: messagesFO,
  },
};

const language = navigator.language.split(/[-_]/)[0]; // language without region code
const languageModel = messages[language] || messages.en;

moment.locale(languageModel.moment);

ReactDOM.render(
  <IntlProvider locale={language} messages={languageModel.messages}>
    <ApolloProvider client={client}>
      <ConfigProvider locale={languageModel.antd}>
        <App />
      </ConfigProvider>
    </ApolloProvider>
  </IntlProvider>,
  document.getElementById('root'),
);
