import React from 'react';
import { Breadcrumb, Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import { FormattedMessage } from 'react-intl';
import logoImg from './img/app-logo-white.png';
import { useAuthToken } from './apollo/authentication';


const AppLayout = ({ children }) => {
  const location = useLocation();
  const { email } = useAuthToken();

  const menuItems = [
    { url: '/', label: <FormattedMessage id="inbox" defaultMessage="Inbox" /> },
  ];

  const selectedMenuItem = menuItems
    .map((i) => i.url)
    .reduce((acc, key) => (location.pathname.indexOf(key) > -1 ? key : acc), '');

  const breadcrumbs = [<NavLink to="/">DCRs</NavLink>];

  const paths = location.pathname.split('/').filter(p => p);
  if (paths.length === 2 && (paths[0] === 'dcr' || paths[0] === 'group')) {
    breadcrumbs.push(paths[1]);
  }

  return (
    <Layout className="layout-container">
      <Layout.Header>
        <div className="logo">
          <NavLink to="/">
            <img src={logoImg} alt="aPureBase Logo" />
          </NavLink>
        </div>
        <div>
          <Menu theme="dark" mode="horizontal" selectedKeys={[selectedMenuItem]} style={{ lineHeight: '64px', justifyContent: 'flex-end' }}>
            <Menu.SubMenu
              key="1"
              title={(
                <span className="submenu-title-wrapper">
                  {email} <DownOutlined />
                </span>
              )}
            >
              <Menu.Item key="logout">
                <NavLink to="/logout">Logout</NavLink>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </div>
      </Layout.Header>
      <Layout.Content style={{ minHeight: 400 }}>
        <Layout style={{ padding: '0 50px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {breadcrumbs.map((b, i) => (
              <Breadcrumb.Item key={i}>{b}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Layout.Content style={{ minHeight: 280, backgroundColor: 'white' }}>
            {children}
          </Layout.Content>
        </Layout>
      </Layout.Content>
    </Layout>
  );
};

export default AppLayout;
