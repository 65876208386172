/* eslint-disable no-underscore-dangle */
import React from 'react';
import ImfPersonDescription from './ImfPersonDescription';
import ImfSiteDescription from './ImfSiteDescription';
import ImfLinkDescription from './ImfLinkDescription';


const ImfEntityDescription = ({ dcr }) => {

  if (dcr.imfEntity.__typename === 'Person') return <ImfPersonDescription dcr={dcr} />;
  if (dcr.imfEntity.__typename === 'Site') return <ImfSiteDescription dcr={dcr} />;
  if (dcr.imfEntity.__typename === 'Affiliation') return <ImfLinkDescription dcr={dcr} />;

  return <></>;
};

export default ImfEntityDescription;
