import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Card, Col, Comment, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getEntityIcon } from '../../../util/Util';
import { DcrEntityType } from '../../../__generated__/globalTypes';

const ImfLinkDescription = ({ dcr }) => {
  const [tabIndex, setTabIndex] = useState('0');

  const CardItem = ({ label, value }) => (
    <Row className="imf-row-container">
      <Col span={7} className="label"><b>{label}</b></Col>
      <Col span={17}>{value}</Col>
    </Row>
  );

  const tabs = [
    {
      key: '0',
      tab: 'Basic information',
      content: (
        <>
          <CardItem label="Local ID" value={dcr.imfEntity.id} />
          <CardItem label="aPureBase ID" value={dcr.imfEntity.apurebaseId} />
          <CardItem label="Status" value={dcr.imfEntity.status ? 'Active' : 'Inactive'} />
          <hr />
          <CardItem label="Pers Local Id" value={dcr.imfEntity.person.localId} />
          <CardItem label="Pers aPB ID" value={dcr.imfEntity.person.apurebaseId} />
          <CardItem label="Pers Gender" value={dcr.imfEntity.person.gender} />
          <CardItem label="Pers Title" value={dcr.imfEntity.person.title} />
          <CardItem label="Pers First Name" value={dcr.imfEntity.person.firstName} />
          <CardItem label="Pers Last Name" value={dcr.imfEntity.person.lastName} />
          <hr />
          <CardItem label="Site Local Id" value={dcr.imfEntity.site.localId} />
          <CardItem label="Site aPB Id" value={dcr.imfEntity.site.apurebaseId} />
          <CardItem label="Site Name" value={dcr.imfEntity.site.name} />
          <CardItem label="Site Zip" value={dcr.imfEntity.site.zipCode} />
          <CardItem
            label="Site Webpage"
            value={(
              <a
                href={dcr.imfEntity.site.webpage}
                target="_blank"
                rel="noopener noreferrer">{dcr.imfEntity.site.webpage}
              </a>
            )} />
          <CardItem label="Site Phone" value={dcr.imfEntity.site.phone} />
        </>
      ),
    },
    {
      key: '1',
      tab: 'Description',
      content: (
        <div>
          <Typography.Paragraph>
            This is a <strong>{dcr.type}</strong> request.
          </Typography.Paragraph>
          <Typography.Paragraph>
            The data under <strong>Basic information</strong> has been loaded from the aPureBase Database to give a better view of who this is.
          </Typography.Paragraph>
        </div>
      ),
    },
  ];

  return (
    <Card
      title={<span>{getEntityIcon(DcrEntityType.Link)} {dcr.imfEntity.name}</span>}
      tabList={tabs}
      activeTabKey={tabIndex}
      onTabChange={setTabIndex}
      extra={dcr.note && <Comment
        author={<b><FormattedMessage id="notes" defaultMessage="Notes" /></b>}
        content={dcr.note}
      />}
    >
      {tabs[tabIndex].content}
    </Card>
  );
};

ImfLinkDescription.FRAGMENT = gql`
    fragment ImfAffiliationDescription on Affiliation {
        id
        apurebaseId
        person {
            apurebaseId
            localId
            status
            gender
            title
            firstName
            lastName
        }
        site {
            apurebaseId
            localId
            type {
                code
                valueWithoutPrefix
            }
            name
            status
            zipCode
            city
            commune {
                code
                valueWithoutPrefix
            }
            county {
                code
                valueWithoutPrefix
            }
            webpage
            phone
        }
    }
`;

export default ImfLinkDescription;
