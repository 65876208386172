import React, { useState } from 'react';
import { message, DatePicker, Dropdown, Menu, Modal } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';
import SettingTwoTone from '@ant-design/icons/lib/icons/SettingTwoTone';
import moment, { Moment } from 'moment';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { DcrGroupDetailQuery_dcrGroup } from './groupDetail/__generated__/DcrGroupDetailQuery';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../localization/LocalizationKeys';

const messages = defineMessages({
  success: {
    id: 'postponed-set-success',
    defaultMessage: 'Successfully set the postpone date',
  },
});

type PostponeDcrActionProps = {
  visible: boolean;
  group: DcrGroupDetailQuery_dcrGroup;
};

const PostponeDcrAction: React.FC<PostponeDcrActionProps> = ({
  visible,
  group: { id, hideUntil },
}) => {
  const localization = useLocalization();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(
    hideUntil ? moment(hideUntil) : null,
  );
  const [saveMutation, { loading }] = useMutation(POSTPONE_MUTATION);

  if (!visible) return <></>;

  const save = () => {
    // Loading the localized text before hand, as [message.success] creates the message outside this react context.
    const onSaveSuccessMsg = localization.formatMessage(messages.success);
    saveMutation({
      variables: {
        groupId: id,
        date: selectedDate,
      },
    }).then(() => message.success(onSaveSuccessMsg))
      .finally(() => setModalVisible(false));
  };

  return (
    <div style={{ display: 'inline' }}>
      <Dropdown
        trigger={['hover']}
        overlay={
          <Menu>
            <Menu.Item onClick={() => setModalVisible(true)}>
              {localization.formatMessage(Locale.postpone_action)}
            </Menu.Item>
          </Menu>
        }
      >
        <SettingTwoTone />
      </Dropdown>

      <Modal
        title={<FormattedMessage id="postpone-action" defaultMessage="Postpone action" />}
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={save}
        okText={<FormattedMessage id="postpone" defaultMessage="Postpone" />}
        cancelText={<FormattedMessage id="cancel" defaultMessage="Cancel" />}
        okButtonProps={{ disabled: selectedDate === (hideUntil ? moment(hideUntil) : undefined), loading }}
      >
        <p>
          <FormattedMessage
            id="postpone-action-description"
            defaultMessage="Postponing a DCR will keep the DCR open, but hide it in the DCR overview until the selected date has been passed."
          />
        </p>
        <div>
          <DatePicker
            disabledDate={(current) => current && current < moment().endOf('day')}
            allowClear
            value={selectedDate}
            onChange={setSelectedDate}
          />
        </div>
      </Modal>
    </div>
  );
};

const POSTPONE_MUTATION = gql`
  mutation PostponeDcr($groupId: Int!, $date: DateTime!) {
    postponeDcr(groupId: $groupId, date: $date) {
      id
      hideUntil
    }
  }
`;

export default PostponeDcrAction;
