import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';

export const TimeAgoLabel = ({ time }) => (
  <Tooltip title={moment(time).format('LLLL')}>
    <span>
      {moment(time).fromNow()}
    </span>
  </Tooltip>
);
