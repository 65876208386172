import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from './auth/LoginPage';
import AppLayout from './AppLayout';
import LogoutPage from './auth/LogoutPage';
import { isAuthenticated } from './apollo/authentication';
import DcrView from './browse/dcr/DcrView';
import DcrGroupDetail from './browse/dcr/groupDetail/DcrGroupDetail';
import DcrSourceDetail from './browse/source/DcrSourceDetail';
import DcrAccountDetail from './browse/account/DcrAccountDetail';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated()
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', from: props.location }} />)}
    />
);
const App = () => (
  <BrowserRouter>
    <Switch>
      <Route
        path="/login"
        render={(props) => {
          const loggedIn = isAuthenticated();
          const { pathname } = props.location.from || { from: { pathname: '/' } };
          let finalPath = pathname;
          if (pathname === '/login' || !pathname) finalPath = '/';
          return loggedIn ? <Redirect to={finalPath} /> : <LoginPage />;
        }}
            />
      <AppLayout>
        <PrivateRoute component={DcrView} path="/" exact />
        <PrivateRoute component={DcrGroupDetail} path="/dcr/:groupId" />
        <PrivateRoute component={DcrSourceDetail} path="/source/:id" />
        <PrivateRoute component={DcrAccountDetail} path="/account/:id" />

        <PrivateRoute component={LogoutPage} path="/logout" />
      </AppLayout>
    </Switch>
  </BrowserRouter>
);

export default App;
