/* eslint-disable max-len */

// MARK: Do not convert this file to typescript

module.exports.Locale = {
  response_description: { id: 'Input.response_description' },
  dcr_not_processed: { id: 'Tooltip.dcr_not_processed' },
  dcr_sent: { id: 'Tooltip.dcr_sent' },
  dcr_waiting_to_be_sent: { id: 'Tooltip.dcr_waiting_to_be_sent' },
  accept: { id: 'accept' },
  account: { id: 'account' },
  active: { id: 'active' },
  all: { id: 'all' },
  apurebase_id: { id: 'apurebase_id' },
  cancel: { id: 'cancel' },
  clear_filters: { id: 'clear_filters' },
  country: { id: 'country' },
  create: { id: 'create' },
  created: { id: 'created' },
  dcr_id: { id: 'dcr_id' },
  dcr_slogan: { id: 'dcr_slogan' },
  dcr_status: { id: 'dcr_status' },
  dcr_opt_out_client: { id: 'dcr_opt_out_client' },
  dcr_opt_out_all: { id: 'dcr_opt_out_all' },
  delete: { id: 'delete' },
  difference: { id: 'difference' },
  email: { id: 'email' },
  entity: { id: 'entity' },
  external_id: { id: 'external_id' },
  field: { id: 'field' },
  filter_postponed: { id: 'filter_postponed' },
  firstname: { id: 'firstname' },
  forgot_password: { id: 'forgot_password' },
  handled: { id: 'handled' },
  id: { id: 'id' },
  inactive: { id: 'inactive' },
  inbox: { id: 'inbox' },
  info: { id: 'info' },
  lastname: { id: 'lastname' },
  local_id: { id: 'local_id' },
  log_in: { id: 'log_in' },
  new_value: { id: 'new_value' },
  not_applicable_short: { id: 'not_applicable_short' },
  notes: { id: 'notes' },
  number_of_fields: { id: 'number_of_fields' },
  old_value: { id: 'old_value' },
  only_postponed: { id: 'only_postponed' },
  open: { id: 'open' },
  password: { id: 'password' },
  please_input_your_email: { id: 'please_input_your_email' },
  please_input_your_password: { id: 'please_input_your_password' },
  postpone: { id: 'postpone' },
  postpone_action: { id: 'postpone-action' },
  postpone_action_description: { id: 'postpone-action-description' },
  postponed_set_success: { id: 'postponed-set-success' },
  refresh: { id: 'refresh' },
  reject: { id: 'reject' },
  remember_me: { id: 'remember_me' },
  response_saved_success: { id: 'response_saved_success' },
  select_response_code: { id: 'select_response_code' },
  sent: { id: 'sent' },
  source: { id: 'source' },
  status: { id: 'status' },
  type: { id: 'type' },
  update: { id: 'update' },
  user: { id: 'user' },
  dcr_empty: { id: 'dcr.empty' },
  button_send_response: { id: 'button.send_response' },
  placeholder_local_id: { id: 'placeholder.local_id' },
  placeholder_search_group_id: { id: 'placeholder.search_group_id' },
  required_local_id: { id: 'required.local_id' },
  required_status: { id: 'required.status' },
  tooltip_external_id: { id: 'tooltip.external_id' },
  tooltip_response_label: { id: 'tooltip.response_label' },
  tooltip_response_message: { id: 'tooltip.response_message' },
};
