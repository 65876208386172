import React, { useState } from 'react';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { Card, Col, Comment, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getEntityIcon } from '../../../util/Util';
import { DcrEntityType } from '../../../__generated__/globalTypes';


const ImfSiteDescription = ({ dcr }) => {
  const [tabIndex, setTabIndex] = useState('0');

  const CardItem = ({ label, value }) => (
    <Row className="imf-row-container">
      <Col span={7} className="label"><b>{label}</b></Col>
      <Col span={17}>{value}</Col>
    </Row>
  );

  const tabs = [
    {
      key: '0',
      tab: 'Basic information',
      content: (
        <>
          <CardItem label="Local ID" value={dcr.imfEntity.localId} />
          <CardItem label="aPureBase ID" value={dcr.imfEntity.apurebaseId} />
          <CardItem label="Status" value={dcr.imfEntity.status ? 'Active' : 'Inactive'} />
          <CardItem label="Site Type" value={get(dcr.imfEntity, 'type.valueWithoutPrefix')} />
          <CardItem label="Commune" value={get(dcr.imfEntity, 'commune.valueWithoutPrefix')} />
          <CardItem label="County" value={get(dcr.imfEntity, 'county.valueWithoutPrefix')} />
          <CardItem label="City" value={dcr.imfEntity.city} />
          <CardItem label="Postal Code" value={dcr.imfEntity.zipCode} />
          <CardItem label="Webpage" value={<a href={dcr.imfEntity.webpage} target="_blank" rel="noopener noreferrer">{dcr.imfEntity.webpage}</a>} />
          <CardItem label="Phone" value={dcr.imfEntity.phone} />
        </>
      ),
    },
    {
      key: '1',
      tab: 'Description',
      content: (
        <div>
          <Typography.Paragraph>
            This is a <strong>{dcr.type}</strong> request.
          </Typography.Paragraph>
          <Typography.Paragraph>
            The data under <strong>Basic information</strong> has been loaded from the aPureBase Database to give a better view of who this is.
          </Typography.Paragraph>
        </div>
      ),
    },
  ];

  return (
    <Card
      title={(
        <span>
          {getEntityIcon(DcrEntityType.Site)} {dcr.imfEntity.name}
        </span>
      )}
      tabList={tabs}
      activeTabKey={tabIndex}
      onTabChange={setTabIndex}
      extra={dcr.note && <Comment
        author={<b><FormattedMessage id="notes" defaultMessage="Notes" /></b>}
        content={dcr.note}
      />}
    >
      {tabs[tabIndex].content}
    </Card>
  );
};

ImfSiteDescription.FRAGMENT = gql`
  fragment ImfSiteDescription on Site {
    id
    apurebaseId
    localId
    type {
      code
      valueWithoutPrefix
    }
    name
    status
    zipCode
    city
    commune {
      code
      valueWithoutPrefix
    }
    county {
      code
      valueWithoutPrefix
    }
    webpage
    phone
  }
`;

export default ImfSiteDescription;
