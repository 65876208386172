
// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
export const b64DecodeUnicode = (str) =>
// Going backwards: from bytestream, to percent-encoding, to original string.
// eslint-disable-next-line no-undef,implicit-arrow-linebreak
  decodeURIComponent(atob(str).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

export const decodeJWT = (token) => {
  const firstIndex = token.indexOf('.');
  const lastIndex = token.lastIndexOf('.');
  const toDecode = token.substring(firstIndex + 1, lastIndex);
  const decoded = b64DecodeUnicode(toDecode);
  return JSON.parse(decoded);
};
