import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import { isAuthenticated, logout } from '../apollo/authentication';

const LogoutPage = () => {
  const client = useApolloClient();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) logout();
    client.resetStore();
    history.replace('/');
  }, [client, history]);

  return <></>;
};


export default LogoutPage;
