import { InMemoryCache } from '@apollo/client';
import possibleTypes from './fragmentTypes.json';



export default new InMemoryCache({
  // https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
  possibleTypes,
  resultCaching: false,
  dataIdFromObject: obj => `${obj.__typename}:${obj.id || obj.code || obj.hash || obj.key || obj.apurebaseId}`,
  typePolicies: { },
});
