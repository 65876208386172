/* eslint-disable no-underscore-dangle,no-plusplus,no-console */
import { ApolloClient, from, createHttpLink } from '@apollo/client';
import { authenticationAfterware, authenticationMiddleware } from './authentication';
import cache from './cache';


export const client = new ApolloClient({
  link: from([
    authenticationMiddleware,
    authenticationAfterware,
    createHttpLink({ uri: '/graphql' }),
  ]),
  cache,
});
