/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Modal } from 'antd';
import ImfEntityDescription from './ImfEntityDescription';

const ImfEntityCardModal = ({ state: [imfEntity, setEntity] }) => (
  <Modal
    visible={!!imfEntity}
    onCancel={() => setEntity(null)}
    closable={false}
    footer={null}
  >
    {imfEntity !== null && <ImfEntityDescription dcr={{ imfEntity }} />}
  </Modal>
);

export default ImfEntityCardModal;
