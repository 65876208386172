import React from 'react';
import { useRouteMatch } from 'react-router';

const DcrSourceDetail = () => {
  const match = useRouteMatch();
  const sourceId = match.params.id;

  return (
    <span>Source ID {sourceId}</span>
  );
};

DcrSourceDetail.propTypes = {};

export default DcrSourceDetail;
